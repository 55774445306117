import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../components/Home/HomeDefault.vue";
import AppContent from "../AppContent";
import SubmissionsProgress from "../views/Reports/SubmissionsProgress.vue";
import RefileFollowup from "../views/Reports/RefileFollowup.vue";
import OpenBalance from "../views/Reports/OpenBalance.vue";

Vue.use(VueRouter);

import guest from "./middleware/guest";
import auth from "./middleware/auth";

import AuthLogin from "../views/Auth/AuthLogin";

import store from "../store";

import middlewarePipeline from "./middlewarePipeline";
import rolesCheck from "./middleware/roles";
import { roles } from "@/constants";

// import Reporting from "../views/Reporting";
import Users from "../views/Users";
import Clients from "../views/Clients";
import Impersonate from "../views/Impersonate";
import Permission from "../views/Permissions";
import ActivateAccount from "@/views/ActivateAccount";
import ResetPassword from "@/views/ResetPassword";
import Entities from "../views/Entities";
import Pod from "@/views/Pod";
import Returns from "@/views/Returns";
import CustomReportsTemplate from "@/views/Fees";
import Maintenance from "@/views/Maintenance";
import EmployeeRetention from "@/views/EmployeeRetention";
import Overpayments from "@/views/Overpayments";
import Deposits from "@/views/Deposits";
import Reconciliation from "@/views/Reconciliation";
import Payments from "@/views/Payments";
import HomeFactory from "@/views/HomeFactory";
import Leads from "@/views/Leads";
import Documents from "@/views/Reports/Documents.vue";
import ClientDocuments from "@/views/Client/ClientDocuments.vue";
import Reports from "@/views/Reports/Reports.vue";
import ReadyToSanitize from "@/views/Reports/ReadyToSanitize.vue";
import ReadyToFinal from "@/views/Reports/ReadyToFinal.vue";
import ReadyToFile from "@/views/Reports/ReadyToFile.vue";
import SanitizeClient from "@/views/SanitizeClient.vue";
import ReadyToReconcile from "@/views/Reports/ReadyToReconcile.vue";
import PageBuilder from "@/views/PageBuilder.vue";
import Resources from "@/views/Resources.vue";
import UnreviwedUploads from "@/views/Reports/UnreviwedUploads.vue";
import RevenueReductionReport from "@/views/Reports/RevenueReductionReport.vue";
import Report4506T from "@/views/Reports/4506T.vue";
import Orphaned from "@/views/Orphaned.vue";
import SanitizationQueue from "@/views/SanitizationQueue.vue";
import DownlineReport from "@/views/Reports/DownlineReport.vue";
import QualificationReport from "@/views/Reports/QualificationReport.vue";
import SupplementReport from "@/views/Reports/SupplementReport.vue";
import UnassignedClients from "@/views/UnassignedClients.vue";
import Performance from "@/views/Performance.vue";
import SignatureVerification from "@/views/SignatureVerification.vue";
import PeoClients from "@/views/Reports/PeoClients.vue";
import OutstandingTasks from "@/views/Reports/OutstandingTasks.vue";
import ClientPerformance from "@/views/Reports/ClientPerformance.vue";
import FinancialPerformance from "@/views/FinancialPerformance.vue";
import ShippingLabels from "@/views/Reports/ShippingLabels.vue";
import ClientDraft from "@/views/Reports/ClientDraft";
import ClientFinal from "@/views/Reports/ClientFinal";
import Releases from "@/views/Releases/Releases.vue";
import SingleRelease from "@/views/Releases/SingleRelease.vue";
import Velocity from "@/views/Reports/Velocity.vue";
import Mandate from "@/views/Mandate.vue";

import SingleMandate from "@/views/SingleMandate.vue";
import MandateLibrary from "@/views/MandateLibrary.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import MandatesSearch from "@/views/MandatesSearch.vue";
import QualificationReview from "@/views/QualificationReview.vue";

import QualificationReviewQueue from "@/views/QualificationReviewQueue.vue";
import Packages from "@/views/Client/Packages.vue";
import EntitiesPage from "@/views/EntitiesPage.vue";
import UsersPage from "@/views/UsersPage.vue";
import Transcripts from "@/views/Client/Transcripts.vue";
import TranscriptReport from "@/views/Reports/Transcripts.vue";
import TranscriptQueue from "@/views/TranscriptQueue/TranscriptQueue.vue";
import SingleTranscriptQueue from "@/views/SingleTranscriptQueue/SingleTranscriptQueue.vue";
import Queues from "@/views/Queues.vue";
import SystemAnnouncements from '@/views/SystemAnnouncements.vue'
import AdoptionTool from "@/views/AdoptionTool.vue";
import Emails from "@/views/Client/Emails.vue";
import PostAdoptReport from "@/views/Reports/PostAdoptReport/PostAdoptReport.vue";
import SingleAdoptedFirm from "@/views/Reports/PostAdoptReport/SingleAdoptedFirm.vue";
import EmailHistoryTool from '@/views/EmailHistoryTool/EmailHistoryTool.vue'
import OverpaymentsView from '@/views/Client/Overpayments/OverpaymentsView.vue'
import NewOverpayment from "@/views/Client/Overpayments/NewOverpayment.vue";
import OverpaymentTool from "@/views/Reports/OverpaymentTool.vue";

const routes = [
  {
    path: "/",
    component: AppContent,
    children: [
      {
        path: "",
        redirect: "/dashboard",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/dashboard",
        component: HomeFactory,
        name: "dashboard",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/returns",
        component: Returns,
        name: "returns",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/reports",
        component: Reports,
        name: "reports",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/permissions",
        component: Permission,
        name: "permissions",
        meta: {
          middleware: [auth],
        },
      },

      {
        path: "/users",
        component: UsersPage,
        name: "users",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/pods",
        component: Pod,
        name: "pods",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/entities",
        component: EntitiesPage,
        name: "entities",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "email-history-tool",
        component: EmailHistoryTool,
        name: 'email-history-tool',
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients",
        component: () => import("@/views/Clients"),
        name: "clients",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients/:clientId",
        redirect: "/clients/:clientId/profile",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients/:clientId/profile",
        name: "client-profile",
        component: () => import("@/views/Client/Profile.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients/:clientId/documents",
        name: "client-documents",
        component: () => import("@/views/Client/ClientDocuments.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients/:clientId/status",
        name: "client-status",
        component: () => import("@/views/Client/Status.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients/:clientId/notes",
        name: "client-notes",
        component: () => import("@/views/Client/Notes.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients/:clientId/tickets",
        name: "client-support-tickets",
        component: () => import("@/views/Client/SupportTickets.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients/:clientId/downloads",
        name: "client-downloads",
        component: () => import("@/views/Client/Downloads.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients/:clientId/admin",
        name: "client-admin",
        component: () => import("@/views/Client/Admin.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients/:clientId/workflow",
        name: "client-workflow",
        component: () => import("@/views/Client/Workflow.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients/:clientId/mandates",
        name: "client-mandates",
        component: () => import("@/views/Client/Mandates.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients/:clientId/shipments",
        name: "client-shipments",
        component: () => import("@/views/Client/Shipment.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients/:clientId/tasks",
        name: "client-tasks",
        component: () => import("@/views/Client/Tasks.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients/:clientId/appeals",
        name: "client-appeals",
        component: () => import("@/views/Client/Appeals/Appeals.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients/:clientId/appeals/new",
        name: "client-new-appeal",
        component: () => import("@/views/Client/Appeals/NewAppeal.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients/:clientId/appeals/:appealId",
        name: "client-single-appeal",
        component: () => import("@/views/Client/Appeals/SingleAppeal.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/support-tickets",
        component: () => import("@/views/SupportTicket.vue"),
        name: "SupportTicket",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/impersonate",
        component: Impersonate,
        name: "impersonate",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/fees",
        component: CustomReportsTemplate,
        name: "fees",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/finances",
        component: EmployeeRetention,
        name: "finances",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/payments",
        component: Payments,
        name: "payments",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/resources",
        component: Resources,
        name: "resources",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/orphaned",
        component: Orphaned,
        name: "orphaned",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/overpayments",
        component: Overpayments,
        name: "overpayments",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/reconciliation",
        component: Reconciliation,
        name: "reconciliation",
        meta: {
          middleware: [auth],
        },
      },

      {
        path: "/deposits",
        component: Deposits,
        name: "deposits",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/leads",
        component: Leads,
        name: "leads",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/required-documents",
        component: Documents,
        name: "required-documents",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/ready-to-sanitize",
        component: ReadyToSanitize,
        name: "ready-to-sanitize",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/ready-for-final",
        component: ReadyToFinal,
        name: "ready-for-final",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/ready-to-reconcile",
        component: ReadyToReconcile,
        name: "ready-to-reconcile",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/:id/jobs/:id",
        component: SanitizeClient,
        name: "sanitize-client",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/queues/sanitize",
        component: SanitizationQueue,
        name: "sanitization-queue",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/queues/ready-to-ship",
        component: ReadyToFile,
        name: "queues-ready-to-ship",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/queues",
        component: Queues,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/queues/qualification",
        component: QualificationReviewQueue,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/queues/transcript",
        component: TranscriptQueue,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/queues/transcript/:id",
        component: SingleTranscriptQueue,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/page-builder/:id",
        component: PageBuilder,
        name: "page-builder",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/unreviewed-documents",
        component: UnreviwedUploads,
        name: "unreviewed-documents",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/revenue-reduction",
        component: RevenueReductionReport,
        name: "revenue-reduction-report",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/post-adopt-report",
        component: PostAdoptReport,
        name: "post-adopt-report",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/overpayment-tool",
        component: OverpaymentTool,
        name: 'overpayment-tool',
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/post-adopt-report/:entityId",
        component: SingleAdoptedFirm,
        name: "single-adopted-firm",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/performance",
        component: Performance,
        name: "performance",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/downline",
        component: DownlineReport,
        name: "downline-report",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/unassigned-clients",
        component: UnassignedClients,
        name: "unassigned-clients",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/queues/unverified-signatures",
        component: SignatureVerification,
        name: "sig-verification",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "peo-clients",
        component: PeoClients,
        name: "peo-clients",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/reports/tasks",
        component: OutstandingTasks,
        name: "outstanding-tasks",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/pending-payments",
        component: SubmissionsProgress,
        name: "pending-payments",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/refile-followup",
        component: RefileFollowup,
        name: "refile-followup",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/open-balance",
        component: OpenBalance,
        name: "open-balance",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients-performance",
        component: ClientPerformance,
        name: "client-performance",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/financial-performance",
        component: FinancialPerformance,
        name: "financial-performance",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/shipping-labels",
        component: ShippingLabels,
        name: "shipping-labels",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients/:id/reports/draft",
        component: ClientDraft,
        name: "client-draft",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/reports/qualifications",
        component: QualificationReport,
        name: "qualification-report",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/reports/4506-T",
        component: Report4506T,
        name: "report-4506T",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/reports/transcripts",
        component: TranscriptReport,
        name: "report-tanscripts",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/reports/supplement",
        component: SupplementReport,
        name: "supplement-report",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients/:id/reports/final",
        component: ClientFinal,
        name: "client-final",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/new/releases",
        component: Releases,
        name: "releases",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/new/releases/:id",
        component: SingleRelease,
        name: "single-release",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/system-announcements",
        component: SystemAnnouncements,
        name: "system-announcements",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/velocity",
        component: Velocity,
        name: "velocity",
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/upload-mandate",
        component: Mandate,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/queues/mandates",
        component: MandateLibrary,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/queues/appeal-letters",
        component: () => import("@/views/Appeals/AppealLetterQueue.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/new-appeals",
        component: () => import("@/views/Appeals/NewAppeals.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/mandate/:id",
        component: SingleMandate,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/search-mandates",
        component: MandatesSearch,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: '/adoption-tool',
        component: AdoptionTool,
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "/mandate/:id",
        component: SingleMandate,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients/:clientId/qualification",
        component: QualificationReview,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients/:clientId/emails",
        component: Emails,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients/:clientId/overpayments",
        component: OverpaymentsView,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients/:clientId/overpayments/new",
        component: NewOverpayment,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients/:clientId/transcripts",
        component: Transcripts,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/clients/:clientId/packages",
        component: Packages,
        meta: {
          middleware: [auth],
        },
      },
    ],
  },

  {
    path: "/login",
    component: AuthLogin,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/activate/*",
    component: ActivateAccount,
  },
  {
    path: "/reset-password/*",
    component: ResetPassword,
  },
  {
    path: "/maintenance",
    component: Maintenance,
  },
  { path: "/404", component: PageNotFound },
  { path: "*", redirect: "/404" },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }

  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
    store,
  };

  return middleware[0]({
    ...context,
    nextMiddleware: middlewarePipeline(context, middleware, 1),
  });
});

export default router;