<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-flex align-center" style="gap: 5px">
        <v-icon @click="handleBackClick" color="primary" style="cursor: pointer">mdi-arrow-left</v-icon>
        <h2>Log a New Overpayment</h2>
        <v-spacer/>
        <h3>Client id: {{ this.clientId }}</h3>
      </v-col>
      <v-col cols="12">
        <v-divider class="mb-6" />
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col>
        <v-progress-circular indeterminate color="primary" />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <v-card rounded elevation="2"class="pa-2">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <div class="d-flex flex-column">
                  <label class="mb-2">Tax Period</label>
                  <v-select
                    outlined
                    dense
                    :items="quarters"
                    v-model="selectedQuarter"
                    hide-details
                  />
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="d-flex flex-column">
                  <label class="mb-2">Notice Date</label>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="noticeDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="noticeDate"
                        append-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="noticeDate"
                      no-title
                      :max="new Date().toISOString()"
                      @change="$refs.menu.save(noticeDate)"
                      scrollable
                    />
                  </v-menu>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="d-flex flex-column">
                  <label class="mb-2">Overpayment Amount</label>
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    hide-details
                    v-model="amount"
                    prefix="$"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="d-flex flex-column">
                  <label class="mb-2">Upload a Scan of the Notice</label>
                  <v-file-input
                    outlined
                    dense
                    show-size
                    v-model="noticeFile"
                    accept="application/pdf"
                    truncate-length="200"
                  ></v-file-input>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="auto">
                <v-btn @click="handleSubmit" :disabled="isSubmitDisabled" :loading="submitLoading" color="primary">Submit the form</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import UploadDocument from "@/components/UploadDocument.vue";
import AuthService from "@/services/auth.service";
import ClientsService from "@/services/clients.service";
import OverpaymentService from "@/services/overpayment.service";

export default {
  name: "NewOverpayment",
  components: { UploadDocument },
  data() {
    return {
      clientId: null,
      quarters: [
        {
          text: 'Q4',
          value: 4
        },
        {
          text: 'Q1',
          value: 1
        },
        {
          text: 'Q2',
          value: 2
        },
        {
          text: 'Q3',
          value: 3
        },
      ],
      menu: false,
      selectedQuarter: undefined,
      noticeDate: undefined,
      amount: undefined,
      loading: true,
      uploadLoading: false,
      submitLoading: false,
      noticeFile: undefined
    };
  },
  methods: {
    handleBackClick() {
      this.$router.push(`/clients/${ this.clientId }/overpayments`);
    },
    async uploadFile(file) {
      const formData = new FormData();

      formData.append("uploaded_file", file);
      formData.append("upload_type", 'filedrop');
      return await ClientsService.uploadFileToClient(this.clientId, formData);
    },
    async handleSubmit() {
      this.submitLoading = true;

      try {
        const { data } = await this.uploadFile(this.noticeFile)

        const payload = {
          upload: data.id,
          notice_date: this.noticeDate,
          amount: this.amount,
          quarter: this.selectedQuarter
        }

        await OverpaymentService.postLetter(this.clientId, payload)

        this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'New Overpayment Logged successfully',
          duration: 4000,
          mode: 'success'
        });

        this.selectedQuarter = undefined
        this.noticeDate = undefined
        this.amount = undefined
        this.noticeFile = undefined
      } finally {
        this.submitLoading = false
      }
    }
  },
  computed: {
    isSubmitDisabled() {
      return !this.selectedQuarter || !this.noticeDate || !this.amount || !this.noticeFile
    }
  },
  async created() {
    this.clientId = this.$route.params.clientId;
    this.loading = false;
  },
};
</script>

<style scoped>

</style>