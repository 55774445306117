<script>
import DOMPurify from "dompurify";
import he from 'he'
import AdoptionsService from "@/services/adoptions.service";

export default {
  name: "EmailList",
  props: {
    email: Object,
    required: true,
  },
  data() {
    return {
      resendLoading: false,
    };
  },
  computed: {
    sanitizedBody() {
      let decoded = he.decode(this.email.body);
      while (decoded !== he.decode(decoded)) {
        decoded = he.decode(decoded);
      }
      return DOMPurify.sanitize(decoded);
    },
  },
  methods: {
    async resendEmail() {
      this.resendLoading = true;

      try {
        await AdoptionsService.resendAdoptionEmail(this.email.id);
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Email resent successfully',
          duration: 4000,
          mode: 'success'
        });
      } finally {
        this.resendLoading = false;
      }
    },
    async downloadFile(file) {
      try {
        const response = await fetch(file.url);
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl
        link.download = file.original_file_name;

        // Simulate click
        link.click();

        // Clean up
        window.URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    }
  }
};
</script>

<template>
  <v-card>
    <v-card-subtitle class="pa-1">
      <v-row
        class="px-2" justify="space-between" align="center" dense style="background-color: #e7f2fd; min-height: 50px;"
      >
        <v-col cols="auto">
          <div>
            <span class="font-weight-bold">From:&nbsp;</span>
            Frascogna Law Group (ertc@frascognalaw.com)
          </div>
        </v-col>
        <v-col cols="auto">
          <div class="d-flex align-center" style="gap: 10px">
            <div>{{ email.sent_at }}</div>
          </div>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-divider />
    <v-card-subtitle class="pa-1">
      <v-row
        class="px-2" justify="space-between" align="center" dense style="background-color: #e7f2fd; min-height: 50px;"
      >
        <v-col cols="auto">
          <div>
            <span class="font-weight-bold">To:&nbsp;</span>
            {{ email.primary_contact }}
          </div>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-divider />
    <v-card-subtitle class="pa-1">
      <v-row
        class="px-2" justify="space-between" align="center" dense style="background-color: #e7f2fd; min-height: 50px;"
      >
        <v-col cols="auto">
          <div class="d-flex flex-wrap">
            <span class="font-weight-bold">Cc:&nbsp;</span>
            <div class="d-flex align-center" v-for="cc in email.cc">
              <span style="cursor: pointer;">{{ cc }}</span>,
              &nbsp;
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-divider />
    <v-card-subtitle class="pa-1">
      <v-row
        class="px-2" justify="space-between" align="center" dense style="background-color: #e7f2fd; min-height: 50px;"
      >
        <v-col cols="auto">
          <div>
            <span class="font-weight-bold">Subject:&nbsp;</span>
            IMPORTANT ERC UPDATE
          </div>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-divider />
    <v-card-subtitle class="pa-1">
      <v-row
        class="px-2" justify="space-between" align="center" dense style="background-color: #e7f2fd; min-height: 50px"
      >
        <v-col cols="auto" class="d-flex" style="gap: 5px">
          <v-icon>mdi-attachment</v-icon>
          <div v-if="email.attachments" class="font-weight-bold">{{ email.attachments.length }} attachment<span
            v-if="email.attachments.length > 1"
          >s</span>:</div>
        </v-col>
        <v-col>
          <div class="d-flex flex-column flex-nowrap" style="gap: 2px">
            <div @click="downloadFile(attachment)" v-for="attachment in email.attachments" style="cursor: pointer">
              <span class="text-decoration-underline">{{ attachment.original_file_name }}</span>
              &nbsp;
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-divider />
    <div class="pa-7">
      <div v-html="sanitizedBody" />
    </div>
    <v-divider />
    <v-card-actions>
      <div class="d-flex justify-end" style="width: 100%">
        <v-btn @click="resendEmail" :loading="resendLoading" color="primary" large>Resend <v-icon right>mdi-send</v-icon></v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<style scoped>
.custom-text-ellipsis {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>