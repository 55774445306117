import { render, staticRenderFns } from "./GeneralComunications.vue?vue&type=template&id=7aea945c&scoped=true&"
import script from "./GeneralComunications.vue?vue&type=script&setup=true&lang=js&"
export * from "./GeneralComunications.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7aea945c",
  null
  
)

export default component.exports