<template>
  <div>
    <ClientHeader :client="client" />
    <v-row>
      <v-col>
        <router-link :to="`/clients/${clientId}/overpayments/new`">
          <v-btn color="primary">Receive New Overpayment</v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col>
        <v-progress-circular indeterminate color="primary" />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="overpayment"
          disable-pagination
          hide-default-footer
          class="elevation-1"
        >
          <template #item.quarter="{ item }">
            <div>Q{{ item.quarter }}</div>
          </template>
          <template #item.overpayment_amount="{ item }">
            <div>
              {{ new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', })
                .format(item.overpayment_amount) }}
            </div>
          </template>
          <template v-slot:item.notice_url="{ item }">
            <a :href="item.notice_url" target="_blank">Link <v-icon small>mdi-open-in-new</v-icon></a>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ClientHeader from "@/components/Client/ClientHeader.vue";
import ClientService from "@/services/clients.service";
import OverpaymentService from "@/services/overpayment.service";

export default {
  name: "OverpaymentTool",
  components: { ClientHeader },
  data() {
    return {
      clientId: null,
      client: null,
      overpayment: undefined,
      loading: true,
      headers: [
        { text: 'Tax Period (Quarter)', value: 'quarter' },
        { text: 'Notice Date', value: 'notice_date' },
        { text: 'Overpayment Amount', value: 'overpayment_amount' },
        { text: 'Link to uploaded notice', value: 'notice_url' },
      ]
    };
  },
  methods: {
    async getCurrentClient() {
      this.client = await ClientService.getOneClient(this.clientId);
    },
    async getOverpayment() {
      try {
        this.overpayment = await OverpaymentService.getOverpaymentsForClient(this.clientId)

      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    this.clientId = this.$route.params.clientId;
    await this.getCurrentClient();
    await this.getOverpayment()

  },
};
</script>

<style scoped>

</style>