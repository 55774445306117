<script>
import AdoptionsService from "@/services/adoptions.service";
import ClientService from "@/services/clients.service";

export default {
  name: "IntroEmail",
  props: {
    entityId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    status: 4,
    clients: [],
    selected: [],
    headers: [
      {
        text: "Client",
        value: "trade_name",
        filterable: true,
      },
      {
        text: "EIN",
        value: "ein",
        filterable: true,
      },
      {
        text: "Legal Name",
        value: "legal_name",
        filterable: true,
      },
    ],
  }),
  methods: {
    async sendEmails() {
      let res = await ClientService.getIntroductionEmailTemplate(this.entityId);
      this.loading = true;
      const total = {
        success: 0,
        failed: 0,
      };
      await Promise.all(
          this.selected.map(async (client) => {
            const uploadId = await this.getOutstanding(client.id);
            if (uploadId) {
              await this.send(client.id, res.introduction_email, uploadId, client.legal_name);
              total.success++;
            }else {
              total.failed++;
            }
          })
      );
      this.$store.dispatch("snackbar/showSnackbarMessage", {
        message: `Emails stats : ${total.success} sent, ${total.failed} have missing CSA.`,
        duration: 4000,
        mode: "success",
      });
      await this.getData()
      this.loading = false;
    },
    async send(clientId,body, upload,legal_name) {
      body = body?.replace(
          /\[Legal Business Name]/g,
          legal_name
      );
      const payload = {
        type: 1,
        body: body,
        cc:[],
        attachments: upload ? [upload] : [],
      };

      await ClientService.sendAdoptionEmail(clientId, payload);
    },
    async getOutstanding(clientId) {
      const res = await ClientService.getUploads(clientId);
      return res.reviewed.find(e =>
          e.document_progress.find(doc =>
              doc.document_name === "Client services agreement" ||
              doc.document_name === "Client Service Agreement"
          )
      )?.id;
    },
    handleRowClick(item) {
      this.$router.push(
        {
          path: `/clients/${ item.id }/emails`,
          query: { adopterId: this.entityId },
        },
      );
    },
    async getData(){
      try {
        this.clients = await AdoptionsService.getAdoptionStatus(this.entityId, this.status);
      } finally {
        this.loading = false;
      }
    }
  },
  async mounted() {
    this.loading = true;
    this.getData();
  },
};
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12" md="10">
        <v-card>
          <v-card-text>
<!--            <v-btn color="primary" :disabled="!selected.length" @click="sendEmails">Send Emails</v-btn>-->
            <v-data-table
              v-model="selected"
              item-key="id"
              :loading="loading"
              :headers="headers"
              :items="clients"
              @click:row="handleRowClick"
              disable-pagination
              hide-default-footer
              show-select
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>
