import $axios from "../plugins/axios";

class OverpaymentService {
  postLetter(clientId, payload) {
    return $axios.post(`/clients/${clientId}/overpayment_letter`, payload).then(res => res.data);
  }

  getReport() {
    return $axios.get(`/overpayment_letters`).then(res => res.data);
  }

  getOverpaymentsForClient(clientId) {
    return $axios.get(`/clients/${clientId}/overpayment_letter`).then(res => res.data);
  }
}

export default new OverpaymentService();