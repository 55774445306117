<template>
  <div>
    <v-row>
      <v-col>
        <h3>Overpayment Tool</h3>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col>
        <v-progress-circular indeterminate color="primary" />
      </v-col>
    </v-row>
    <v-row v-else class="mt-10 elevation-1" style="row-gap: 20px">
      <v-col cols="12" md="3" class="pa-0" style="border: 1px solid #E0E7ED">
        <div class="d-flex flex-column">
          <div class="d-flex justify-center align-center" style="background-color: #E1EEF9; height: 50px">
            <div class="text-center" >Q4</div>
          </div>
          <v-data-table
            disable-pagination
            hide-default-footer
            :items="overpayments['4']"
            :headers="headers"
            @click:row="handleRowClick"
          >
            <template #item.age="{ item }">
              {{ item.age }} days
            </template>
          </v-data-table>
        </div>
      </v-col>
      <v-col cols="12" md="3" class="pa-0" style="border: 1px solid #E0E7ED">
        <div class="d-flex flex-column">
          <div class="d-flex justify-center align-center" style="background-color: #E1EEF9; height: 50px">
            <div class="text-center" >Q1</div>
          </div>
          <v-data-table
            disable-pagination
            hide-default-footer
            :items="overpayments['1']"
            :headers="headers"
            @click:row="handleRowClick"
          >
            <template #item.age="{ item }">
              {{ item.age }} days
            </template>
          </v-data-table>
        </div>
      </v-col>
      <v-col cols="12" md="3" class="pa-0" style="border: 1px solid #E0E7ED">
        <div class="d-flex flex-column">
          <div class="d-flex justify-center align-center" style="background-color: #E1EEF9; height: 50px">
            <div class="text-center" >Q2</div>
          </div>
          <v-data-table
            disable-pagination
            hide-default-footer
            :items="overpayments['2']"
            :headers="headers"
            @click:row="handleRowClick"
          >
            <template #item.age="{ item }">
              {{ item.age }} days
            </template>
          </v-data-table>
        </div>
      </v-col>
      <v-col cols="12" md="3" class="pa-0" style="border: 1px solid #E0E7ED">
        <div class="d-flex flex-column">
          <div class="d-flex justify-center align-center" style="background-color: #E1EEF9; height: 50px">
            <div class="text-center" >Q3</div>
          </div>
          <v-data-table
            disable-pagination
            hide-default-footer
            :items="overpayments['3']"
            :headers="headers"
            @click:row="handleRowClick"
          >
            <template #item.age="{ item }">
              {{ item.age }} days
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OverpaymentService from "@/services/overpayment.service";

export default {
  name: "OverpaymentTool",
  data() {
    return {
      loading: true,
      overpayments: [],
      headers: [
        { text: 'Client', value: 'legal_name' },
        { text: 'Age', value: 'age' },
      ]
    };
  },
  methods: {
    async getOverpayments() {
      try {
        this.overpayments = await OverpaymentService.getReport();
      } finally {
        this.loading = false;
      }
    },
    handleRowClick(row) {
      this.$router.push(`/clients/${row.client_id}/overpayments`);
    }
  },
  created() {
    this.getOverpayments();
  }
};
</script>

<style scoped>

</style>