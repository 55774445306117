<script setup>
import {onMounted, ref} from 'vue'
import AdoptionsService from "@/services/adoptions.service";
import ClientsService from "@/services/clients.service";
import {func as $func} from "@/globalFunctions";

const props = defineProps(['entityId'])
const loading = ref(false)
const status = ref(0)
const emailTemplate = ref('')
const selected = ref([])
const disableSend = ref(true)
const headers = ref([
  {
    text: 'Client',
    filterable: true,
    value: 'trade_name'
  },
  {
    text: 'EIN',
    filterable: true,
    value: 'ein'
  },
  {
    text: 'Legal Name',
    filterable: true,
    value: 'legal_name'
  }
])

const clients = ref([])
const handleRowClick = (item) => {
  window.location.href = `/clients/${item.id}`
}
const  getData = async () => {
  loading.value = true
  try {
    clients.value = await AdoptionsService.getAdoptionStatus(props.entityId, status.value)
  } finally {
    loading.value = false
  }
}
const loadIntroductionEmailTemplate = async () => {
  ClientsService.getIntroductionEmailTemplate(props.entityId)
      .then(res => {
        emailTemplate.value = res.general_email
        if (res.general_email) {
          disableSend.value = false
        }else {
          $func.popMessage(`General Communications template is not set!`, 'fail')
        }
      })
}
const sendEmails = async (all = false) => {
  try {
    loading.value = true
    const payload = {
      body: emailTemplate.value,
      clients: all ? [] : selected.value.map(client => client.id)
    }
    await AdoptionsService.sendEmails(props.entityId, payload)
    selected.value = []
    $func.popMessage('Emails sent successfully!', 'success')
    await getData()
  } finally {
    loading.value = false
  }
}
onMounted(() => {
  getData()
  loadIntroductionEmailTemplate()
})
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12" md="10">
        <v-card>
          <v-card-title class="d-flex justify-space-around">
            <v-btn color="warning" :disabled="disableSend" :loading="loading" @click="() => sendEmails(true)">Send to All</v-btn>
            <v-btn color="primary" :loading="loading" @click="()=>sendEmails(false)" :disabled="!selected.length || disableSend">Send to Selected</v-btn>

          </v-card-title>
          <v-card-text>
            <v-data-table
                v-model="selected"
                show-select
                :headers="headers"
                @click:row="handleRowClick"
                :items="clients"
                item-key="id"
                :loading="loading"
                disable-pagination
                hide-default-footer
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>

<script>
export default {
  name: 'GeneralComunications'
}
</script>
