<script>
import EmailComponent from '@/components/System/EmailHistoryTool/EmailComponent.vue';
import AdoptionsService from "@/services/adoptions.service";

export default {
  name: "EmailHistoryTool",
  components: {EmailComponent},
  data: () => ({
    emails: [],
    selectedEmailIndex: undefined,
    selectedEmail: null,
    search: "",
    searchLoading: false,
  }),
  watch: {
    selectedEmailIndex: function(val) {
      if (val !== null) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.selectedEmail = this.emails[val];
      }
    },
    search: function (val) {
      this.searchEmail(val)
    }
  },
  methods: {
    async searchEmail(value) {
      this.searchLoading = true;

      try {
        this.emails = await AdoptionsService.searchAdoptionEmail(value)
      } finally {
        this.searchLoading = false;
      }
    }
  },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },
  mounted() {
    this.searchEmail(this.search)
  }
};
</script>

<template>
  <div>
    <v-row>
      <v-col>
        <div class="text-h5 font-weight-bold">Email History Tool</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="!selectedEmail || !isMobile" cols="12" md="4">
        <v-card>
          <v-card-title>
            <v-row justify="space-between" align="center">
              <v-col cols="auto">All messages</v-col>
            </v-row>
          </v-card-title>
          <v-divider />
          <v-card-subtitle>
            <v-row justify="center">
              <v-col cols="12">
                <v-text-field
                  dense
                  v-model="search"
                  outlined
                  label="Search email address"
                  placeholder="Search by CC or To"
                  prepend-inner-icon="mdi-magnify"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-text v-if="searchLoading">
            <v-progress-circular
              color="primary"
              indeterminate
            />
          </v-card-text>
          <v-card-text v-else class="pa-0">
            <v-list three-line>
              <v-list-item-group
                v-model="selectedEmailIndex"
                active-class="blue--text"
              >
                <div v-if="!emails.length && search">
                  <p class="text-center">No emails found</p>
                </div>
                <div v-for="(email, index) in emails" :key="email.id">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title v-text="'Frascogna Law Group'" />
                      <v-list-item-subtitle v-text="'ertc@frascognalaw.com'" />
                      <v-list-item-subtitle>
                        <div class="custom-text-ellipsis">IMPORTANT ERC UPDATE</div>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <div
                          class="d-flex align-center mt-2 text-caption" style="gap: 5px; opacity: 40%; color: black"
                        >
                          <v-icon small>mdi-clock-outline</v-icon>
                          <div>{{ email.sent_at ?? 'Scheduled' }}</div>
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="selectedEmail" cols="12" md="8">
        <v-row>
          <v-col class="d-flex align-center d-md-none" v-if="selectedEmail && isMobile">
            <v-btn @click="selectedEmailIndex = undefined" icon>
              <v-icon color="black">mdi-arrow-left</v-icon>
            </v-btn>
            <span class="font-weight-bold">Back</span>
          </v-col>
        </v-row>
        <br>
        <EmailComponent :email="selectedEmail" />
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.custom-text-ellipsis {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-border-bottom {
  border-bottom: 1px solid black;
  transform: scaleY(0.5);
  transform-origin: bottom;
}

</style>