<script>
import AdoptionsService from "@/services/adoptions.service";
import { PDFDocument,rgb } from 'pdf-lib'
import flg_letter  from '../../../public/FLG_letter.pdf'
import ClientsService from "@/services/clients.service";

export default {
  name: "IntroLetter",
  props: {
    entityId: {
      type: Number,
      required: true
    },
    entity: {
      type: Object,
    }
  },
  data:  () => ({
    pdfPath: flg_letter,
    selectedLetters: [],
    search: '',
    loading: false,
    category: 0,
    printLoading: false,
    markLoading: false,
    exportLoading: false,
    downloadLetterEnabled: true,
    status: 3,
    letterUrl: '',
    headers: [
      {
        text: 'Name',
        filterable: true,
        value: 'name'
      },
      {
        text: 'Email',
        filterable: true,
        value: 'contact_email'
      },
      {
        text: 'Company Name',
        filterable: true,
        value: 'legal_name'
      },
      {
        text: 'Address',
        filterable: true,
        value: 'address'
      },
    ],
    letters: []
  }),
  methods: {
    async getData(filter) {
      this.loading = true
      this.selectedLetters = []

      try {
        this.letters = await AdoptionsService.getAdoptionStatus(this.entityId, this.status, filter)
      } finally {
        this.loading = false
      }
    },
    async markAsShipped() {
      this.markLoading = true

      const payload = {
        letter_stage: 2
      }

      try {
        for (let i = 0; i < this.selectedLetters.length; i++) {
          let client = this.selectedLetters[i]
          await AdoptionsService.patchAdoption(client.adoption_id, payload)
        }
      } finally {
        this.markLoading = false;
      }

      await this.getData(1)
    },
    async print() {
      this.printLoading = true

      const payload = {
        letter_stage: 1
      }

      try {
        for (let i = 0; i < this.selectedLetters.length; i++) {
          let client = this.selectedLetters[i]
          await AdoptionsService.patchAdoption(client.adoption_id, payload)
        }
      } finally {
        this.printLoading = false;
      }

      await this.getData()
    },
    getLetter(){
      ClientsService.getIntroductionEmailTemplate(this.$route.params.entityId).then(res => {
        this.letterUrl = res.url
      })
    },
    async modifyPDF(business_name) {
      // const response = await fetch('/FLG_letter.pdf')
      const response = await fetch(this.letterUrl)
      const pdfBytes = await response.arrayBuffer()
      const pdfDoc = await PDFDocument.load(pdfBytes)

      const newPdfDoc = await PDFDocument.create()
      const [copiedPage] = await newPdfDoc.copyPages(pdfDoc, [0])
      newPdfDoc.addPage(copiedPage)

      const page = newPdfDoc.getPages()[0]
      page.drawRectangle({
        x: 85,
        y: 680,
        width: 200,
        height: 30,
        color: rgb(1, 1, 1),
      })
      page.drawText(business_name + ',', {
        x: 90,
        y: 694,
        size: 10
      })
      const modifiedPdfBytes = await newPdfDoc.save()
      const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = business_name + '_letter.pdf'
      link.click()
    },
    downloadLetter() {
      if (!this.letterUrl) {
        this.downloadLetterEnabled = false
        return this.$store.dispatch("snackbar/showSnackbarMessage", {
          message: `Letter Template is not uploaded yet`,
          duration: 4000,
          mode: "fail",
        });
      }
      this.selectedLetters.forEach(e=>{
        this.modifyPDF(e.legal_name)
      })
    },
    async exportToCSV() {
      this.exportLoading = true

      try {
          const res = await AdoptionsService.exportIntroLetterToCSV(this.entityId, this.status)
          const raw = await fetch(res.url)
          const blob = await raw.blob()

          let link = document.createElement('a')

          link.href = URL.createObjectURL(blob)
          link.download = `${this.entity.name}_printed.csv`
          document.body.appendChild(link);
          link.click()
          document.body.removeChild(link);
      } finally {
        this.exportLoading = false
      }
    }
  },
  watch: {
    category(newValue) {
      this.getData(newValue)
    }
  },
  async mounted() {
    await this.getData()
    await this.getLetter()
  }
};
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12" md="10">
        <v-card>
          <v-row>
            <v-col class="d-flex justify-center align-center">
              <v-radio-group row v-model="category">
                <v-radio label="New Category" :value="0"/>
                <v-radio label="Printed Category" :value="1" />
                <v-radio label="Shipped Category" :value="2"/>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :loading="loading"
              :headers="headers"
              :items="letters"
              :search="search"
              :show-select="category !== 2"
              v-model="selectedLetters"
              disable-pagination
              hide-default-footer
            >
              <template #item.name="{ item }">
                {{ item.contact_first }} {{ item.contact_last }}
              </template>
              <template #item.address="{ item }">
                {{ item.state }}, {{ item.street }}
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions v-if="category === 0">
            <v-btn :disabled="!selectedLetters.length" @click="print" :loading="printLoading" color="primary">Print</v-btn>
          </v-card-actions>
          <v-card-actions v-else-if="category === 1">
            <div class="d-flex justify-space-between align-center" style="width: 100%">
              <v-btn color="primary" @click="downloadLetter" :disabled="!selectedLetters.length || !downloadLetterEnabled">Download Letter Template</v-btn>
              <v-btn color="primary" :disabled="!selectedLetters.length" @click="markAsShipped" :loading="markLoading">Mark as Shipped</v-btn>
              <v-btn color="primary" :loading="exportLoading" @click="exportToCSV">Export to CSV</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>
