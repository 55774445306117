import { render, staticRenderFns } from "./NewOverpayment.vue?vue&type=template&id=546e8dc4&scoped=true&"
import script from "./NewOverpayment.vue?vue&type=script&lang=js&"
export * from "./NewOverpayment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "546e8dc4",
  null
  
)

export default component.exports