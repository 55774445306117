<script>
import AdoptionReview from "@/components/SinglePostAdoptFirm/AdoptionReview.vue";
import IntroLetter from "@/components/SinglePostAdoptFirm/IntroLetter.vue";
import IntroEmail from "@/components/SinglePostAdoptFirm/IntroEmail.vue";
import ContactInitiated from "@/components/SinglePostAdoptFirm/ContactInitiated.vue";
import DocumentVerification from "@/components/SinglePostAdoptFirm/DocumentVerification.vue";
import Problematic from "@/components/SinglePostAdoptFirm/Problematic.vue";
import WaitingSigned8821 from "@/components/SinglePostAdoptFirm/WaitingSigned8821.vue";
import WaitingToBeFiled from "@/components/SinglePostAdoptFirm/WaitingToBeFiled.vue";
import FiledWithIRS from "@/components/SinglePostAdoptFirm/FiledWithIRS.vue";
import AwaitingIRSPayments from "@/components/SinglePostAdoptFirm/AwaitingIRSPayments.vue";
import RefileNeeded from "@/components/SinglePostAdoptFirm/RefileNeeded.vue";
import UnresponsiveClientsSecondAttempt from "@/components/SinglePostAdoptFirm/UnresponsiveClientsSecondAttempt.vue";
import UnresponsiveClients from "@/components/SinglePostAdoptFirm/UnresponsiveClients.vue";
import UnresponsiveClientsThirdAttempt from "@/components/SinglePostAdoptFirm/UnresponsiveClientsThirdAttempt.vue";
import CollectingTranscript from "@/components/SinglePostAdoptFirm/CollectingTranscript.vue";
import TranscriptVerified from "@/components/SinglePostAdoptFirm/TranscriptVerified.vue";
import AdoptionsService from "@/services/adoptions.service";
import ClientsService from "@/services/clients.service";
import DenialReceived from "@/components/SinglePostAdoptFirm/DenialReceived.vue";
import PaymentReceived from "@/components/SinglePostAdoptFirm/PaymentReceived.vue";
import FeesReceived from "@/components/SinglePostAdoptFirm/FeesReceived.vue";
import Completed from "@/components/SinglePostAdoptFirm/Completed.vue";
import { PDFDocument, rgb } from "pdf-lib";
import UploadDocument from "@/components/UploadDocument.vue";
import AuthService from "@/services/auth.service";
import BadContact from "@/components/SinglePostAdoptFirm/BadContact.vue";
import GeneralComunications from "@/components/SinglePostAdoptFirm/GeneralComunications.vue";

export default {
  name: "SingleAdoptedFirm",
  components: { UploadDocument, AdoptionReview, IntroLetter, IntroEmail, ContactInitiated },
  data: () => ({
    entityId: "",
    component: null,
    loading: true,
    uploadIntroductionLetter: false,
    uploadLoading: false,
    updateIntroductionLoading: false,
    updateFirstLoading: false,
    updateSecondLoading: false,
    updateThirdLoading: false,
    updateGeneralLoading: false,
    stats: [],
    introductionEmail: {},
    introductionEmailBody: "",
    unresponsiveFirstEmailBody: "",
    unresponsiveSecondEmailBody: "",
    unresponsiveThirdEmailBody: "",
    generalCommunication: "",
    downloadLoading: false,
    templateLoading: false,
    currentUser: "",
    selectedStatus: 0,
    entity: {},
  }),
  methods: {
    async handleIntroductionEmailUpload(files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        await this.uploadFile(file.uploadedFile, "introduction-letter");
      }

      this.uploadIntroductionLetter = false;
      this.loadIntroductionEmailTemplate();
    },

    async uploadFile(file, type) {
      const formData = new FormData();

      formData.append("uploaded_file", file);
      formData.append("upload_type", type);
      formData.append("entity_id", this.entityId);

      this.uploadLoading = true;
      try {
        await ClientsService.uploadFileToClient(0, formData);
      } finally {
        this.uploadLoading = false;
      }
    },
    async downloadLetter() {
      this.downloadLoading = true;

      try {
        const raw = await fetch(this.introductionEmail.url);
        const blob = await raw.blob();

        let link = document.createElement("a");

        link.href = URL.createObjectURL(blob);
        link.download = this.introductionEmail.original_file_name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } finally {
        this.downloadLoading = false;
      }
    },
    loadIntroductionEmailTemplate() {
      this.templateLoading = true;

      ClientsService.getIntroductionEmailTemplate(this.entityId)
      .then(res => {
        this.introductionEmail = res
        this.introductionEmailBody = res.introduction_email;
        this.unresponsiveFirstEmailBody = res.unresponsive_first;
        this.unresponsiveSecondEmailBody = res.unresponsive_second;
        this.unresponsiveThirdEmailBody = res.unresponsive_third;
        this.generalCommunication = res.general_email
      })
      .finally(() => {
        this.templateLoading = false;
      });
    },

    async updateIntroductionEmailTemplate() {
      this.updateIntroductionLoading = true;

      try {
        const payload = {
          introduction_email: this.introductionEmailBody,
        };

        await ClientsService.updateIntroductionEmailTemplate(this.entityId, payload);
        this.loadIntroductionEmailTemplate();
      } finally {
        this.updateIntroductionLoading = false;
      }
    },
    async updateGeneralTemplate() {
      this.updateGeneralLoading = true;

      try {
        const payload = {
          general_email: this.generalCommunication,
        };

        await ClientsService.updateIntroductionEmailTemplate(this.entityId, payload);
        this.loadIntroductionEmailTemplate();
      } finally {
        this.updateGeneralLoading = false;
      }
    },

    async updateUnresponsiveFirst() {
      this.updateFirstLoading = true;

      try {
        const payload = {
          unresponsive_first: this.unresponsiveFirstEmailBody,
        };

        await ClientsService.updateIntroductionEmailTemplate(this.entityId, payload);
        this.loadIntroductionEmailTemplate();
      } finally {
        this.updateFirstLoading = false;
      }
    },

    async updateUnresponsiveSecond() {
      this.updateSecondLoading = true;

      try {
        const payload = {
          unresponsive_second: this.unresponsiveSecondEmailBody,
        };

        await ClientsService.updateIntroductionEmailTemplate(this.entityId, payload);
        this.loadIntroductionEmailTemplate();
      } finally {
        this.updateSecondLoading = false;
      }
    },

    async updateUnresponsiveThird() {
      this.updateThirdLoading = true;

      try {
        const payload = {
          unresponsive_third: this.unresponsiveThirdEmailBody,
        };

        await ClientsService.updateIntroductionEmailTemplate(this.entityId, payload);
        this.loadIntroductionEmailTemplate();
      } finally {
        this.updateThirdLoading = false;
      }
    },

    loadInitialComponent() {
      const component = this.$route.hash.substring(1);

      switch (component) {
        case "adoption-review":
          this.component = AdoptionReview;
          break;
        case "intro-letter":
          this.component = IntroLetter;
          break;
        case "intro-email":
          this.component = IntroEmail;
          break;
        case "contact-initiated":
          this.component = ContactInitiated;
          break;
        case "document-verification":
          this.component = DocumentVerification;
          break;
        case "unresponsive-clients":
          this.component = UnresponsiveClients;
          break;
        case "unresponsive-clients-second-attempt":
          this.component = UnresponsiveClientsSecondAttempt;
          break;
        case "unresponsive-clients-third-attempt":
          this.component = UnresponsiveClientsThirdAttempt;
          break;
        case "Problematic":
          this.component = Problematic;
          break;
        case "waiting-signed-8821":
          this.component = WaitingSigned8821;
          break;
        case "waiting-to-be-filed":
          this.component = WaitingToBeFiled;
          break;
        case "filed-with-irs":
          this.component = FiledWithIRS;
          break;
        case "collecting-transcript":
          this.component = CollectingTranscript;
          break;
        case "transcript-verified":
          this.component = TranscriptVerified;
          break;
        case "awaiting-irs-payments":
          this.component = AwaitingIRSPayments;
          break;
        case "refile-needed":
          this.component = RefileNeeded;
          break;
        case "denial-received":
          this.component = DenialReceived;
          break;
        case "payment-received":
          this.component = PaymentReceived;
          break;
        default:
          this.component = null;
      }
    },
    loadComponent(status) {
      this.selectedStatus = status;
      switch (status) {
        case 1:
          this.component = AdoptionReview;
          break;
        case 3:
          this.component = IntroLetter;
          break;
        case 4:
          this.component = IntroEmail;
          break;
        case 5:
          this.component = ContactInitiated;
          break;
        case 6:
          this.component = DocumentVerification;
          break;
        case 7:
          this.component = UnresponsiveClients;
          break;
        case 8:
          this.component = UnresponsiveClientsSecondAttempt;
          break;
        case 9:
          this.component = UnresponsiveClientsThirdAttempt;
          break;
        case 10:
          this.component = Problematic;
          break;
        case 11:
          this.component = WaitingSigned8821;
          break;
        case 12:
          this.component = WaitingToBeFiled;
          break;
        case 13:
          this.component = FiledWithIRS;
          break;
        case 14:
          this.component = CollectingTranscript;
          break;
        case 15:
          this.component = TranscriptVerified;
          break;
        case 16:
          this.component = AwaitingIRSPayments;
          break;
        case 17:
          this.component = RefileNeeded;
          break;
        case 18:
          this.component = DenialReceived;
          break;
        case 19:
          this.component = PaymentReceived;
          break;
        case 20:
          this.component = FeesReceived;
          break;
        case 21:
          this.component = Completed;
          break;
        case 22:
          this.component = BadContact;
          break;
        case 23:
          this.component = GeneralComunications;
          break;
      }
      window.scroll(0, 0);
    },
    async loadAdoptionStats() {
      try {
        this.stats = await AdoptionsService.getAdoptionStats(this.entityId);
      } catch (e) {
        console.error(e);
      }
    },
    async loadEntity() {
      this.entity = await ClientsService.getEntityById(this.entityId);
    },
    handleBackClick() {
      this.component = null;
      this.loadAdoptionStats();
    },
  },
  async mounted() {
    this.entityId = this.$route.params.entityId;
    if (this.$route.hash)
      this.loadInitialComponent();

    const data = await AuthService.getCurrentUserFromCookie();
    this.currentUser = data.data;

    await this.loadAdoptionStats();
    await this.loadEntity();

    this.loadIntroductionEmailTemplate();
    this.loading = false;

  },
};
</script>

<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
        :size="30"
        :width="3"
        color="white"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-row v-if="!loading">
      <v-col cols="12">
        <div class="d-flex justify-space-between mb-2" style="border-bottom: 1px solid gray;">
          <div class="d-flex align-center" style="gap: 10px">
            <v-icon @click="$router.push('/post-adopt-report')" color="black">mdi-arrow-left</v-icon>
            <div class="font-weight-bold text-body-1">{{ entity.name }}</div>
          </div>
          <p><span class="font-weight-bold">ID:</span> {{ entity.id }}</p>
        </div>
      </v-col>
      <v-row v-if="component" justify="center">
        <v-col cols="11" class="d-flex align-center" style="gap: 10px">
          <v-icon @click="handleBackClick" color="black">mdi-arrow-left</v-icon>
          <div class="text-h6">{{ component.name.replace(/([a-z])([A-Z])/g, "$1 $2") }}</div>
        </v-col>
        <v-col cols="11">
          <component :is="component" :entityId="+entityId" :entity="entity" />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col
                  @click="loadComponent(item.status)" cols="12" v-for="item in stats" :key="item.title"
                  v-ripple
                  style="border-bottom: 1px solid #00000012; cursor: pointer;"
                >
                  <v-sheet class="d-flex align-center justify-space-between">
                    <div class="text-h6">{{ item.name }}</div>
                    <v-chip small color="primary">{{ item.count }}</v-chip>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-expansion-panels multiple>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <p style="font-weight: bold">Upload Introduction Letter Template</p>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row justify="center">
                  <v-col>
                    <v-btn @click="uploadIntroductionLetter = !uploadIntroductionLetter" color="primary" block>Upload Introduction Letter Template</v-btn>
                  </v-col>
                  <v-col v-if="uploadIntroductionLetter" cols="12">
                    <div style="border: 1px solid gray;" class="pa-4 rounded-lg">
                      <UploadDocument
                        :loading="uploadLoading" @upload="handleIntroductionEmailUpload" prop-id="upload-intro"
                        :current-user="currentUser" :accept="'application/pdf'"
                      />
                    </div>
                  </v-col>
                  <v-col>
                    <v-btn
                      :disabled="!introductionEmail.url" :loading="downloadLoading" @click="downloadLetter"
                      color="primary" block
                    >Download Introduction Letter Template</v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <p style="font-weight: bold">Update Introduction Email Template</p>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div style="border: 1px solid gray;" class="pa-4 rounded-lg">
                  <p class="font-weight-bold text-h6">Introduction Email</p>
                  <v-skeleton-loader type="card" v-if="templateLoading" />
                  <VueEditor v-else v-model="introductionEmailBody" />
                </div>
                <br>
                <v-row>
                  <v-col>
                    <v-btn @click="updateIntroductionEmailTemplate" :loading="updateIntroductionLoading" block color="primary">Update</v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <p style="font-weight: bold">Update Unresponsive 1st Email Template</p>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div style="border: 1px solid gray;" class="pa-4 rounded-lg">
                  <p class="font-weight-bold text-h6">Unresponsive 1st Email</p>
                  <v-skeleton-loader type="card" v-if="templateLoading" />
                  <VueEditor v-else v-model="unresponsiveFirstEmailBody" />
                </div>
                <br>
                <v-row>
                  <v-col>
                    <v-btn @click="updateUnresponsiveFirst" :loading="updateFirstLoading" block color="primary">Update</v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <p style="font-weight: bold">Update Unresponsive 2nd Email Template</p>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div style="border: 1px solid gray;" class="pa-4 rounded-lg">
                  <p class="font-weight-bold text-h6">Unresponsive 2nd Email</p>
                  <v-skeleton-loader type="card" v-if="templateLoading" />
                  <VueEditor v-else v-model="unresponsiveSecondEmailBody" />
                </div>
                <br>
                <v-row>
                  <v-col>
                    <v-btn @click="updateUnresponsiveSecond" :loading="updateSecondLoading" block color="primary">Update</v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <p style="font-weight: bold">Update Unresponsive 3rd Email Template</p>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div style="border: 1px solid gray;" class="pa-4 rounded-lg">
                  <p class="font-weight-bold text-h6">Unresponsive 3rd Email</p>
                  <v-skeleton-loader type="card" v-if="templateLoading" />
                  <VueEditor v-else v-model="unresponsiveThirdEmailBody" />
                </div>
                <br>
                <v-row>
                  <v-col>
                    <v-btn @click="updateUnresponsiveThird" :loading="updateThirdLoading" block color="primary">Update</v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <p style="font-weight: bold">Update General Communication Emails</p>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div style="border: 1px solid gray;" class="pa-4 rounded-lg">
                  <p class="font-weight-bold text-h6">General Communication Emails</p>
                  <v-skeleton-loader type="card" v-if="templateLoading" />
                  <VueEditor v-else v-model="generalCommunication" />
                </div>
                <br>
                <v-row>
                  <v-col>
                    <v-btn @click="updateGeneralTemplate" :loading="updateGeneralLoading" block color="primary">Update</v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-row>
  </div>
</template>

<style scoped>

</style>
