<script>
import ClientHeader from "@/components/Client/ClientHeader.vue";
import ClientService from "@/services/clients.service";

export default {
  name: "Emails",
  components: { ClientHeader },
  data: () => ({
    client: null,
    generating: 5, // 1 - intro, 2 - unresponsive 1st, 3 - unresponsive 2nd, 4 - unresponsive 3rd,
    sendLoading: false,
    clientId: "",
    adopterId: undefined,
    comesFromReport: false,
    body: "",
    bodyLoading: false,
    emailTemplates: '',
    emailSent: false,
    CSAUploaded: false,
    CSAID: null,
    sentEmails: [],
    emailsLoading: false,
    loading: true,
    managingAttorneysLoading: false,
    selectedManagingAttorneys: [],
    managingAttorneys: [],
    ccAOR: false,
    contactEmails: [],
    headers: [
      {
        text: "Client",
        value: "trade_name",
        filterable: true,
      },
      {
        text: "EIN",
        value: "ein",
        filterable: true,
      },
      {
        text: "Type of Email",
        value: "status_name",
        filterable: true,
      },
      {
        text: "Date",
        value: "date",
      },
    ],
  }),
  methods: {
    handleGoBack() {
      this.$router.push(`/post-adopt-report/${this.adopterId}#intro-email`);
    },
    async getOutstanding() {
      const res = await ClientService.getUploads(this.clientId);
      res.reviewed.forEach(e=>{
        if (e.document_progress.find(doc => doc.document_name === "Client services agreement" || doc.document_name === "Client Service Agreement")){
          this.CSAID = e.id;
          this.CSAUploaded = true
        }
      })
    },
    async getCurrentClient() {
      this.client = await ClientService.getOneClient(this.clientId);
    },

    async getEmailTemplates() {
      if (this.emailTemplates) return this.emailTemplates

      this.bodyLoading = true;

      try {
        let res = await ClientService.getIntroductionEmailTemplate(this.client.managing_firm.id);

        res.introduction_email = res.introduction_email?.replace(
            /\[Legal Business Name]/g,
            this.client.legal_name
        );

        res.unresponsive_first = res.unresponsive_first?.replace(
            /\[Legal Business Name]/g,
            this.client.legal_name
        );

        res.unresponsive_second = res.unresponsive_second?.replace(
            /\[Legal Business Name]/g,
            this.client.legal_name
        );

        res.unresponsive_third = res.unresponsive_third?.replace(
            /\[Legal Business Name]/g,
            this.client.legal_name
        );

        this.emailTemplates = res;

      } finally {
        this.bodyLoading = false;
      }
    },

    async getTemplate(legal_name) {
      switch (this.generating) {
        case 1:
          this.body = this.emailTemplates.introduction_email;
          if (!this.body) {
            this.$store.dispatch("snackbar/showSnackbarMessage", {
              message: "Introduction email template is not set for this entity!",
              duration: 4000,
              mode: "error",
            });
          }
          break;
        case 2:
          this.body = this.emailTemplates.unresponsive_first;
          if (!this.body) {
            this.$store.dispatch("snackbar/showSnackbarMessage", {
              message: "Unresponsive 1st attempt template is not set for this entity!",
              duration: 4000,
              mode: "error",
            });
          }
          break;
        case 3:
          this.body = this.emailTemplates.unresponsive_second;
          if (!this.body) {
            this.$store.dispatch("snackbar/showSnackbarMessage", {
              message: "Unresponsive 2nd attempt template is not set for this entity!",
              duration: 4000,
              mode: "error",
            });
          }
          break;
        case 4:
          this.body = this.emailTemplates.unresponsive_third;
          if (!this.body) {
            this.$store.dispatch("snackbar/showSnackbarMessage", {
              message: "Unresponsive 3rd attempt template is not set for this entity!",
              duration: 4000,
              mode: "error",
            });
          }
          break;
      }
    },
    async getSentEmails() {
      this.emailsLoading = true;
      if (!this.adopterId) {
        return;
      }

      try {
        this.sentEmails = await ClientService.getSentEmails(this.adopterId);
      } finally {
        this.emailsLoading = false;
      }
    },
    async send() {
      this.sendLoading = true;
      const {role, id, ...aor } = this.client.attorney_of_record;
      let managingAttorneys = this.selectedManagingAttorneys.map(item => {
        return {first: item.first, last: item.last, email: item.email}
      });

      let contacts = this.contactEmails.map(item => {
        return {email: item}
      })

      let cc = managingAttorneys.concat(contacts)

      if (this.ccAOR)
        cc.push(aor)

      try {
        const payload = {
          type: this.generating,
          body: this.body,
          cc,
          attachments: this.CSAID ? [this.CSAID] : [],
        };

        await ClientService.sendAdoptionEmail(this.clientId, payload);
        this.$store.dispatch("snackbar/showSnackbarMessage", {
          message: "Email sent successfully!",
          duration: 4000,
          mode: "success",
        });
        this.emailSent = true;
        this.getSentEmails();
      } finally {
        this.sendLoading = false;
      }
    },
    async getManagingAttorneys() {
      this.managingAttorneysLoading = true;

      try {
        const entity = await ClientService.getEntityById(this.client.managing_firm.id);
        this.managingAttorneys = entity.users.filter(item => item.role_id === 4);
      } finally {
        this.managingAttorneysLoading = false;
      }
    },
    async getAdoptive(){
      await ClientService.getAdoptive(this.clientId).then(res => {
        this.adopterId = res
      })
    },
    getCardTitle() {
      let title;
      // 1 - intro, 2 - unresponsive 1st, 3 - unresponsive 2nd, 4 - unresponsive 3rd,
      switch (this.generating) {
        case 1:
          title = "Introduction Email"
          break;
        case 2:
          title = "Unresponsive 1st"
          break;
        case 3:
          title = "Unresponsive 2nd"
          break;
        case 4:
          title = "Unresponsive 3rd"
          break;
      }

      return title
    },
    validateEmail(email) {
      return String(email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    },
    handleInput(items) {
      const item = items[items.length - 1]
      if (!this.validateEmail(item))
        this.contactEmails.splice(this.contactEmails.indexOf(item), 1)
    }
  },
  async mounted() {
    this.clientId = this.$route.params.clientId;
    this.adopterId = this.$route.query.adopterId;
    await this.getCurrentClient();
    await this.getOutstanding();
    if (!this.adopterId) {
      await this.getAdoptive()
    } else {
      this.comesFromReport = true;
    }
    this.loading = false;

    this.getEmailTemplates()
    this.getManagingAttorneys();
    this.getSentEmails();
  },
  watch: {
    generating() {
      this.getTemplate(this.client.legal_name);
    },
  },
};
</script>

<template>
  <div>
    <ClientHeader :client="client" />
    <v-row v-if="loading">
      <v-col>
        <v-progress-circular
          :size="30"
          :width="3"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-container v-else>
      <v-row>
        <v-col>
          <v-alert type="success" dense v-if="emailSent">
            Email sent successfully! Go back to <a :href="`/post-adopt-report/${this.comesFromReport ? this.adopterId : this.client.managing_firm.id}`">List of clients</a>.
          </v-alert>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="auto">
          <v-btn color="primary" @click="generating = 1">Generate Introduction</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn color="primary" @click="generating = 2">Generate Unresponsive 1st</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn color="primary" @click="generating = 3">Generate Unresponsive 2nd</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn color="primary" @click="generating = 4">Generate Unresponsive 3rd</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card v-if="generating !== 5">
            <v-card-title>
              <div style="width: 100%">
                <v-row align="center">
                  <v-col cols="auto">{{ getCardTitle() }}</v-col>
                  <v-col cols="auto">
                    <v-checkbox v-model="ccAOR" label="CC AOR" />
                  </v-col>
                  <v-col cols="auto">
                    <v-select
                      hide-details
                      :loading="managingAttorneysLoading"
                      v-model="selectedManagingAttorneys"
                      item-text="email"
                      key="id"
                      return-object
                      :items="managingAttorneys"
                      multiple
                      outlined
                      chips
                      dense
                      label="CC Managing Attorney"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-combobox
                      v-model="contactEmails"
                      chips
                      clearable
                      label="Add contacts"
                      @input="handleInput"
                      placeholder="Press TAB or ENTER after each email"
                      outlined
                      dense
                      hide-details
                      multiple
                    >
                      <template v-slot:selection="{ attrs, item, select, selected }">
                        <v-chip
                            v-bind="attrs"
                            :input-value="selected"
                            close
                            @click="select"
                            @click:close="contactEmails.splice(contactEmails.indexOf(item), 1)"
                        >
                          <strong>{{ item }}</strong>&nbsp;
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
              </div>
            </v-card-title>
            <v-card-text>
              <v-skeleton-loader type="card" v-if="bodyLoading" />
              <VueEditor v-else v-model="body" />
              <br>
              <v-alert v-if="!CSAUploaded" type="error" dense>
                CSA document is not uploaded for client, upload it from
                <a :href="`/clients/${clientId}/documents`">documents</a>
                tab.
              </v-alert>
              <v-alert v-else type="success" dense>
                CSA document is uploaded for client.
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-row align="center" justify="end">
                <v-col cols="auto">
                  <v-btn @click="send" :disabled="!CSAUploaded" :loading="sendLoading" color="primary">
                    Send
                    <v-icon right>mdi-send</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="comesFromReport">
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="sentEmails"
                :loading="emailsLoading"
                disable-pagination
                hide-default-footer
                item-key="id"
              >
                <template #item.date="{ item }">
                    {{ new Date(new Date().setDate(new Date().getDate() - item.days_in_status)).toLocaleDateString() }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-btn @click="handleGoBack" color="primary"><v-icon>mdi-arrow-left</v-icon> Go back to emails report</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>

</style>
